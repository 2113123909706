body, #root {
  /* background-image: url('https://www.marthadebayle.com/wp-content/uploads/2020/05/enfermeria.jpg'); */
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  /* background-color: #282c34; */
  /* background-size: 100% 100%; */
  /* display: flex; */
  height: 100vh;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
}

/* .main_div {
  background-color: rgba(40, 44, 52, 0.8);
  height: 100%;
  background-size: 100% 100%;
  flex-direction: column;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
} */

div {
  border: 0px solid red;
}
